<template>
  <el-main>
    <!-- <div class="topImg">
      <img src="@/assets/image/temHeader.png" alt="" />
    </div> -->
    <div class="chooseStyle" ref="chooseStyle">
      <div v-for="(item, index) in allStyle" :key="index" :class="['styleItem', chooseStyleIndex === index ? 'isActive' : '']" @click="chooseStyle(index)">
        {{ item }}
      </div>
    </div>
    <div class="styleList" v-show="chooseStyleIndex === 0">
      <div
        :style="{ backgroundColor: styleActive.id === item.id ? '#409EFF' : '#f8f9fb', color: styleActive.id === item.id ? '#fff' : '#333' }"
        @click="chooseStyleItem(item)"
        v-for="(item, index) in sonStyleArr"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="templateList">
      <div class="blankTem" @click="useBlankTem">
        <i class="el-icon-circle-plus-outline"></i>
        <div>使用空白模板</div>
      </div>
      <div
        class="templateItem"
        :style="{ transform: mouseEnterId == item.id ? 'translateY(-10px)' : '' }"
        v-for="(item, index) in temList"
        :key="index"
        @mouseenter="mouseEnterId = item.id"
        @mouseleave="mouseEnterId = -1"
      >
        <img :src="item.images" alt="" />
        <div class="botDesc">
          <div v-if="chooseStyleIndex === 0 && item.is_free !== undefined" class="price" :style="{ color: item.is_free === 0 || item.is_pay === 1 ? '#2da641' : 'red' }">
            {{ item.is_free === 0 ? '免费' : item.is_pay === 1 ? '已购买' : '￥' + item.money + '元起' }}
          </div>
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.details }}</div>
          <div class="platform"></div>
        </div>
        <div v-show="mouseEnterId == item.id" class="mask">
          <div @click="lookTemFun(item)">预览模版</div>
          <div @click="useTem(item)" v-if="item.is_free === 0 || item.is_pay || chooseStyleIndex === 1">使用模版</div>
          <div @click="goBuy(item)" v-if="item.is_free === 1 && !item.is_pay">购买模版</div>
        </div>
      </div>
    </div>
    <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
    <el-dialog @closed="dialogClose" :title="!buyTemFlag ? '预览模版' : '购买模版'" :visible.sync="lookTemFlag" :width="buyTemFlag ? '40%' : '30%'">
      <div class="lookBox">
        <div class="imgBox">
          <img :src="activeTem.images" alt="" />
        </div>
        <div class="temDescBox">
          <div>
            <div class="name">{{ activeTem.name }}</div>
            <div class="details">{{ activeTem.details }}</div>
            <div class="price" v-if="chooseStyleIndex == 0" :style="{ color: activeTem.is_free === 0 || activeTem.is_pay === 1 ? '#2da641' : 'red' }">
              {{ activeTem.is_free === 0 ? '免费' : activeTem.is_pay === 1 ? '已购买' : '￥' + activeTem.money + '元起' }}
            </div>
          </div>
          <div v-if="!buyTemFlag">
            <div class="tips">扫描二维码预览</div>
            <div class="tips">预览效果仅供参考，请以实际投放效果为准</div>
            <div class="qrBox">
              <img :src="activeTem.qrcode" alt="" />
            </div>
          </div>
          <div v-if="buyTemFlag">
            <div class="payClassBox">
              <div @click="createOrder(2)" :class="[payStyle == 2 ? 'isActive' : '']">
                <img class="jiao" v-show="payStyle == 2" src="@/assets/image/jiao.png" alt="" />
                <img style="margin-right: 5px" src="@/assets/image/ali.png" alt="" />
                支付宝支付
              </div>
              <div @click="createOrder(1)" :class="[payStyle == 1 ? 'isActive' : '']">
                <img class="jiao" v-show="payStyle == 1" src="@/assets/image/jiao.png" alt="" />
                <img style="margin-right: 5px" src="@/assets/image/wx.png" alt="" />
                微信支付
              </div>
            </div>
            <div class="payQrCode">
              <div id="qrcode" ref="qrcode"></div>
              <div class="payMask" v-show="payFlag">
                <i class="el-icon-check"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="!buyTemFlag" @click="lookTemFlag = false">取 消</el-button>
        <el-button v-if="!directBuy && buyTemFlag" @click="buyTemFlag = false">返回</el-button>
        <el-button v-if="activeTem.is_free && !activeTem.is_pay && !buyTemFlag" type="primary" @click="createOrder(2)">购买模版</el-button>
        <el-button v-if="activeTem.is_pay || !activeTem.is_free" type="primary" @click="useTem(activeTem)">立即使用</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import QRCode from 'qrcodejs2'
import Paging from '../../../components/paging.vue'
let aliTime = null
let wxTime = null
export default {
  props: {
    sonStyleArr: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Paging
  },
  data() {
    return {
      allStyle: ['行业模版', '我的模板'],
      chooseStyleIndex: 0,
      styleActive: { id: 0, name: '全部模版' },
      page: 1,
      pageNum: 13,
      total: 0,
      temList: [],
      mouseEnterId: -1,
      lookTemFlag: false,
      activeTem: {},
      buyTemFlag: false,
      payStyle: 2,
      aliPayQr: '',
      aliPayID: 0,
      wxPayQr: '',
      wxPayID: 0,
      payFlag: false,
      directBuy: false
    }
  },

  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val
      } else {
        this.page = val
      }
      this.getTemList()
    },
    chooseStyle(index) {
      this.page = 1
      let that = this
      let obj = {}
      if (index == 0) {
        obj = {
          page: that.page,
          rows: that.pageNum,
          industry_id: this.styleActive.id
        }
      } else {
        obj = {
          page: that.page,
          rows: that.pageNum
        }
      }
      this.$axios.post(this.$api.shopDiy.myTemList, obj).then((res) => {
        if (res.code == 0) {
          this.chooseStyleIndex = index
          that.temList = res.result.list
          that.total = res.result.total_number
        }
      })
    },
    chooseStyleItem(item) {
      this.styleActive = item
      this.getTemList()
    },
    getTemList() {
      let that = this
      this.$axios
        .post(this.$api.shopDiy.temItemList, {
          page: that.page,
          rows: that.pageNum,
          industry_id: this.styleActive.id
        })
        .then((res) => {
          if (res.code == 0) {
            that.temList = res.result.list
            that.total = res.result.total_number
          }
        })
    },
    lookTemFun(item) {
      this.activeTem = item
      this.lookTemFlag = true
    },
    createQr(statsu) {
      this.$nextTick(() => {
        this.$refs.qrcode.innerHTML = ''
        let qr_code = ''
        if (statsu == 1) {
          qr_code = this.wxPayQr
        } else {
          qr_code = this.aliPayQr
        }
        let qrcode = new QRCode('qrcode', {
          width: 132,
          height: 132,
          text: qr_code, // 二维码地址
          colorDark: '#000',
          colorLight: '#fff'
        })
      })
    },
    backFn() {
      this.$axios
        .post(this.$api.shopDiy.getOrderStatus, {
          id: this.payStyle == 1 ? this.wxPayID : this.aliPayID
        })
        .then((res) => {
          if (res.code === 0) {
            if (res.result.status == 1) {
              this.$message.success(`${this.payStyle == 1 ? '微信' : '支付宝'}支付成功`)
              this.payFlag = true
              this.activeTem.is_pay = 1
              if (this.payStyle == 1) {
                clearInterval(wxTime)
              } else {
                clearInterval(aliTime)
              }
            }
          }
        })
    },
    getOrder(statsu) {
      let that = this
      this.$axios
        .post(this.$api.shopDiy.createOrder, {
          template_creation_id: this.activeTem.id,
          pay_type: statsu
        })
        .then((res) => {
          if (res.code === 0) {
            if (statsu === 1) {
              that.wxPayQr = res.result.data
              that.wxPayID = res.result.id
              wxTime = setInterval(that.backFn, 1000)
            } else {
              that.aliPayQr = res.result.data.qr_code
              that.aliPayID = res.result.id
              aliTime = setInterval(that.backFn, 1000)
            }
            that.createQr(statsu)
          }
        })
    },
    createOrder(statsu) {
      let that = this
      this.buyTemFlag = true
      this.payStyle = statsu
      if (statsu === 1 && aliTime) {
        clearInterval(aliTime)
        aliTime = null
      }
      if (statsu == 1 && this.wxPayID && !wxTime) {
        // 计时器被清空了
        wxTime = setInterval(that.backFn, 1000)
      }
      if (statsu === 2 && wxTime) {
        clearInterval(wxTime)
        wxTime = null
      }
      if (statsu == 2 && this.wxPayID && !aliTime) {
        // 计时器被清空了
        aliTime = setInterval(that.backFn, 1000)
      }
      if (statsu == 2 && !this.aliPayQr) {
        this.getOrder(statsu)
      } else if (statsu == 1 && !this.wxPayQr) {
        this.getOrder(statsu)
      } else if (this.aliPayQr || this.wxPayQr) {
        this.createQr(statsu)
      }
    },
    dialogClose() {
      if (aliTime) {
        clearInterval(aliTime)
      }
      if (wxTime) {
        clearInterval(wxTime)
      }
      // 关闭弹框清空所有数据
      this.activeTem = {}
      this.buyTemFlag = false
      this.payStyle = 2
      this.aliPayQr = ''
      this.aliPayID = 0
      this.wxPayQr = ''
      this.wxPayID = 0
    },
    // 查询模板数据并加入我的模板
    selectAndAdd(item) {
      let that = this
      sessionStorage.clear('val')
      sessionStorage.clear('#_update')
      setTimeout(() => {
        sessionStorage.setItem('#_cNum', 1)
      }, 400)
      sessionStorage.setItem('#_update', 1)
      this.$axios
        .post(this.$api.shopDiy.getTemplateInfo, {
          id: this.chooseStyleIndex === 0 ? item.id : item.template_creation_id
        })
        .then((res) => {
          if (res.code == 0) {
            res.result.page_values = JSON.parse(res.result.page_values)
            sessionStorage.setItem(
              'val',
              JSON.stringify({
                data: res.result.page_values
              })
            )
            return true
          }
        })
        .then((res) => {
          if (item.is_free === 0 && this.chooseStyleIndex === 0) {
            this.$axios.post(this.$api.shopDiy.addMyTem, {
              template_creation_id: item.id
            })
          }
          that.$router.push('/addPages/decorationComponents')
        })
    },
    useTem(item) {
      let that = this
      if (sessionStorage.getItem('#_cNum') && Number(sessionStorage.getItem('#_cNum')) != 0) {
        that
          .$confirm('您的页面改动尚未保存，是否新建自定义页面!!!', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(() => {
            this.selectAndAdd(item)
          })
      } else {
        this.selectAndAdd(item)
      }
    },
    useBlankTem() {
      if (sessionStorage.getItem('#_cNum') && Number(sessionStorage.getItem('#_cNum')) != 0) {
        this.$confirm('您的页面改动尚未保存，是否新建自定义页面!!!', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          sessionStorage.clear('val')
          sessionStorage.clear('#_update')
          setTimeout(() => {
            sessionStorage.setItem('#_cNum', 0)
          }, 400)
          this.$router.push({
            path: '/addPages/decorationComponents',
            query: {
              page_type: 1
            }
          })
        })
      } else {
        sessionStorage.clear('val')
        sessionStorage.clear('#_update')
        setTimeout(() => {
          sessionStorage.setItem('#_cNum', 0)
        }, 400)
        this.$router.push({
          path: '/addPages/decorationComponents',
          query: {
            page_type: 1
          }
        })
      }
    },
    goBuy(item) {
      // 购买
      this.directBuy = true
      this.activeTem = item
      this.lookTemFlag = true
      this.$nextTick(() => {
        this.createOrder(2)
      })
    }
  },
  watch: {
    chooseStyleIndex: {
      handler(val) {
        if (!val) {
          this.getTemList()
        } else {
          this.page = 1
          let that = this
          this.$axios
            .post(this.$api.shopDiy.myTemList, {
              page: that.page,
              pageNum: that.pageNum
            })
            .then((res) => {
              if (res.code == 0) {
                that.temList = res.result.list
                that.total = res.result.total_number
              }
            })
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.topImg {
  width: 100%;
  img {
    width: 100%;
  }
}
.chooseStyle {
  display: flex;
  margin: 0 0 24px 0;
  border-bottom: 1px solid #f2f3f5;
  position: relative;
  .styleItem {
    padding: 12px 0;
    margin-right: 40px;
    cursor: pointer;
    position: relative;
  }
  .isActive {
    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 60%;
      height: 2px;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #409eff;
    }
  }
}
.styleList {
  display: flex;
  flex-wrap: wrap;
  & > div {
    padding: 0px 16px;
    text-align: center;
    min-width: 80px;
    min-height: 28px;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
    flex-shrink: 0;
    font-size: 12px;
    margin-bottom: 16px;
  }
  & > div:not(:first-child) {
    margin-left: 16px;
  }
}
.templateList {
  display: flex;
  flex-wrap: wrap;
  & > div {
    width: 200px;
    // height: 360px;
    border: 1px solid #e3e4e6;
    margin-right: 24px;
    margin-bottom: 24px;
    position: relative;
    transition: all 0.3s;
    & > img {
      width: 100%;
    }
    .botDesc {
      width: 100%;
      height: 122px;
      padding: 15px;
      position: absolute;
      bottom: 0px;
      left: 0;
      z-index: 2;
      background-color: #fff;
      .price {
        font-size: 16px;
      }
      .name {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        margin: 10px 0 8px;
      }
      .desc {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-size: 12px;
        color: #7d7e80;
        line-height: 18px;
        margin-bottom: 8px;
      }
    }
    .mask {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 60px;
      & > div {
        width: 96px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        padding: 0 16px;
        font-size: 12px;
        margin: 10px auto;
      }
      & > div:first-child {
        color: #fff;
        border: 1px solid #fff;
      }
      & > div:first-child:hover {
        color: #409eff;
        border: 1px solid #409eff;
      }
      & > div:last-child {
        color: #fff;
        background-color: #409eff;
      }
      & > div:last-child:hover {
        background-color: #3773da;
      }
    }
  }
  .blankTem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #979899;
    cursor: pointer;
    min-height: 392px;
    i {
      color: #cacbcc;
      font-size: 32px;
      margin-bottom: 12px;
    }
  }
}
.lookBox {
  display: flex;
  .imgBox {
    width: 200px;
    height: 356px;
    float: left;
    margin-left: 20px;
    border: 1px solid #f2f2f2;
    overflow: hidden;
    margin-right: 60px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .temDescBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .name {
      font-size: 16px;
      color: #323233;
    }
    .details {
      font-size: 12px;
      color: #96979a;
      line-height: 18px;
      margin-top: 10px;
    }
    .price {
      margin-top: 12px;
      font-size: 16px;
      transform: translateX(10px);
    }
    .tips {
      font-size: 12px;
      color: #96979a;
      line-height: 24px;
      margin-bottom: 8px;
    }
    .qrBox {
      width: 140px;
      height: 140px;
      border: 1px solid #ebddf0;
      img {
        width: 100%;
      }
    }
    .payClassBox {
      display: flex;
      & > div {
        width: 150px;
        height: 50px;
        border: 1px solid #e4e4e4;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
        margin-right: 10px;
        position: relative;
        .jiao {
          position: absolute;
          z-index: 2;
          right: 0;
          bottom: 0;
          width: 24px;
          height: 20px;
          margin: 0;
        }
      }
      .isActive {
        border: 2px solid red;
      }
    }

    .payQrCode {
      margin-top: 10px;
      width: 132px;
      height: 132px;
      position: relative;
      img {
        width: 100%;
      }
      .payMask {
        position: absolute;
        top: 0;
        left: 0;
        width: 132px;
        height: 132px;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 70px;
          font-weight: bold;
          color: #2fb72f;
        }
      }
    }
  }
}
</style>
